import React from "react"

import Layout from "../components/Layout/Layout"
import CompanyHero from "../components/CompanyHero/CompanyHero"
import ServiceList from "../components/ServiceList/ServiceList"
import Certifications from "../components/Certifications/Certifications"

const ServicesPage = ({ pageContext: { locale } }) => {
  const metadata = {
    title: "servicesPage.metadata.title",
    description: "servicesPage.metadata.description",
  }

  return (
    <Layout locale={locale} metadata={metadata}>
      <ServiceList />
      <Certifications />
    </Layout>
  )
}

export default ServicesPage
