import React from "react"

import * as S from "./style"
import { Title, Text } from "../UI/Type"

import oscpImage from "../../images/oscp@1x.svg"
import cehImage from "../../images/ceh@1x.svg"

import useBaffle from '../../utils/useBaffle'

import { FormattedMessage } from 'react-intl'

const About = () => {
  const displayRef = React.useRef()

  useBaffle([displayRef])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Column>
            <Title ref={displayRef}>
              <FormattedMessage id="servicesPage.certifications.name" />
            </Title>
          </S.Column>
          <S.Column>
            <Text medium>
              <FormattedMessage id="servicesPage.certifications.text" />
            {/* To deliver a premium service according to the best practices and standards,
            Adamant’s colaborators are trained and certified by the best market organizations,
            with certifications such as <strong>C|EH</strong> (Certified Ethical Hacker), <strong>OSCP </strong>
            (Offensive Security Certified Professional), <strong>CCNP</strong> (Cisco Certified Network Professional),
            <strong> CIPP/E</strong> (Certified Information Privacy Professional/Europe) and <strong>CIPM</strong> (Certified Information Privacy Manager). */}
            </Text>
            <S.Network>
              <S.Company>
                <S.OSCP src={oscpImage} alt={'OSCP'} />
                <Text medium>
                  <FormattedMessage id="servicesPage.certifications.certificationList.oscp" />
                </Text>
              </S.Company>
              <S.Company>
                <S.OSCP src={cehImage} alt={'CEH'} />
                <Text medium>
                  <FormattedMessage id="servicesPage.certifications.certificationList.ceh" />
                </Text>
              </S.Company>
            </S.Network>
          </S.Column>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default About
