import React from "react"

import * as S from "./style"
import { Title, Text } from "../UI/Type"
import useBaffle from '../../utils/useBaffle'

import { FormattedMessage } from 'react-intl'

const ServiceList = () => {
  const displayRef = React.useRef()

  useBaffle([displayRef])

  return (
    <S.Wrapper>
      <S.Container>
      <S.Content>
        <S.Column>
          <Title ref={displayRef}>
            <FormattedMessage id="servicesPage.services.name" />
          </Title>
        </S.Column>
        <S.Column>
          <Text medium>
          <FormattedMessage id="servicesPage.services.text" />
          </Text>
          <S.Team>
            <S.Title>
              <FormattedMessage id="servicesPage.services.serviceList.pentest.name" />
            </S.Title>
            <S.Text medium>
              <FormattedMessage id="servicesPage.services.serviceList.pentest.description" />
            </S.Text>
          </S.Team>
          <S.Team>
            <S.Title>
              <FormattedMessage id="servicesPage.services.serviceList.redTeam.name" />
            </S.Title>
            <S.Text medium>
              <FormattedMessage id="servicesPage.services.serviceList.redTeam.description" />
            </S.Text>
          </S.Team>
          <S.Team>
            <S.Title>
              <FormattedMessage id="servicesPage.services.serviceList.blueTeam.name" />
            </S.Title>
            <S.Text medium>
              <FormattedMessage id="servicesPage.services.serviceList.blueTeam.description" />
            </S.Text>
          </S.Team>
          <S.Team>
            <S.Title>
              <FormattedMessage id="servicesPage.services.serviceList.consulting.name" />
            </S.Title>
            <S.Text medium>
              <FormattedMessage id="servicesPage.services.serviceList.consulting.description" />
            </S.Text>
          </S.Team>
        </S.Column>
      </S.Content>

      </S.Container>
    </S.Wrapper>
  )
}

export default ServiceList
